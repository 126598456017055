<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/group-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Sub Groups
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="10%" class="p-2 light-blue-bg">Action</th>
						<th width="20%" class="p-2 light-blue-bg">Name</th>
						<th width="40%" class="p-2 light-blue-bg">Description</th>
						<th width="10%" class="p-2 light-blue-bg">Assets</th>
						<th width="10%" class="p-2 light-blue-bg">Asset Stocks</th>
						<th width="10%" class="p-2 light-blue-bg">Inventories</th>
					</tr>
				</thead>
				<tbody v-if="subgroups.length">
					<tr v-for="(row, index) in subgroups" :key="index">
						<td width="10%" class="p-2 border-top-light-grey">
							<v-tooltip z-index="9" right content-class="custom-right-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										dark
										x-small
										color="green"
										v-bind="attrs"
										v-on="on"
										class="mr-3"
										v-on:click.stop.prevent="routeToUpdate(row)"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit Sub Group</span>
							</v-tooltip>
							<v-tooltip z-index="9" top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										dark
										x-small
										color="red"
										v-bind="attrs"
										v-on="on"
										v-on:click.stop.prevent="deleteConfirm(row)"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete Sub Group</span>
							</v-tooltip>
						</td>
						<td width="20%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="name" label="name"></ShowValue>
						</td>
						<td width="40%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="description" label="description"></ShowValue>
						</td>
						<td width="10%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="total_asset" label="assets"></ShowValue>
						</td>
						<td width="10%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="total_asset_stock" label="asset stocks"></ShowValue>
						</td>
						<td width="10%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="total_inventory" label="inventories"></ShowValue>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="6">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no sub group at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<DeleteTemplate
			type="Sub Group"
			:delete-text="deleteText"
			v-on:success="
				deleteClear();
				getSubGroups();
			"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			:delete-url="deleteURL"
			:delete-note="
				subgroup.activated
					? 'To preserve associated records, you could de-activate the Sub Group instead.'
					: null
			"
		>
		</DeleteTemplate>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { EventBus } from "@/core/event-bus/event.bus";
import { cloneDeep } from "lodash";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			subgroup: {},
			subgroups: [],
			pageLoading: true,
			deleteText: null,
			deleteDialog: false,
			deleteURL: null,
		};
	},
	methods: {
		routeToDetail(row) {
			console.log({ row });
		},
		routeToUpdate(row) {
			const sub_group = cloneDeep(row);
			EventBus.$emit("update:sub-group", sub_group);
		},
		deleteClear() {
			this.deleteDialog = false;
			this.deleteURL = null;
			this.deleteText = null;
		},
		deleteConfirm({ uuid, name }) {
			this.deleteDialog = true;
			this.deleteURL = `${this.type}/${this.typeUuid}/sub-group/${uuid}`;
			this.deleteText = name;
		},
		getSubGroups() {
			ApiService.get(`${this.type}/${this.typeUuid}/sub-group`)
				.then(({ data }) => {
					this.subgroups = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ShowValue,
		DeleteTemplate,
	},
	beforeDestroy() {
		EventBus.$off("reload:sub-group");
	},
	mounted() {
		this.getSubGroups();

		EventBus.$on("reload:sub-group", () => {
			this.getSubGroups();
		});
	},
};
</script>
